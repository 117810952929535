import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";

import './App.css';
import './components/notification/Notify.css';

import LoginPage from './pages/login/Login';
import HomePage from './pages/Home';
import AboutPage from './pages/About';
import MyShopPage from './pages/myshop/MyShop';
import PasswordResetPage from './pages/login/PasswordReset';
import MarketPlacePage from './pages/market/MarketPlace';
import ShopSellerPage from './pages/market/ShopSeller';
import ProductViewPage from './pages/market/ProductView';
import MyCartPage from './pages/market/MyCart';
import MyPaymentPage from './pages/market/MyPayment';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const app_hostname = 'https://api.meya.co.th';
  const [systemdata_productcategorylist, setSystemdata_productcategorylist] = useState([]);
  const [systemdata_productitemlist, setSystemdata_productitemlist] = useState([]);
  const [systemdata_shoporderlist, setystemdata_shoporderlist] = useState([]);
  const [systemdata_shopitemlist, setSystemdata_shopitemlist] = useState([]);
  const [mycartlen, setMycartlen] = useState(0)
  const [myorderlen, setMyorderlen] = useState(0);

  const [islogin, setIslogin] = useState(false);
  const [isConnectionOpen_notify, setIsConnectionOpen_notify] = useState(false);
  const [messages_raw, setMessages_raw] = useState({});
  const [messages_chat_buffer, setMessages_chat_buffer] = useState({});
  const [messages_chat_unread, setMessages_chat_unread] = useState(0);
  const notify_ws = useRef();
  const [notify_data, setNotify_data] = useState([]);
  const [notify_count, setNotify_count] = useState(0);

  const [popup_register, setpopup_register] = useState(0);
  const [popup_signin, setpopup_signin] = useState(0);

  function generate_token(length) {
    var a = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890".split("");
    var b = [];
    for (var i = 0; i < length; i++) {
      var j = (Math.random() * (a.length - 1)).toFixed(0);
      b[i] = a[j];
    }
    return b.join("");
  }

  const getTokenObject = () => {
    const tokenString = sessionStorage.getItem('meya-token4');
    if (tokenString !== "undefined" && tokenString !== undefined && tokenString !== null) {
      const userToken = JSON.parse(tokenString);
      return userToken
    }
    return {
      shop_id: "-",
      shop_email: "-",
      shop_username: "-",
      shop_firstname: "-",
      shop_lastname: "-",
      shop_name: "-",
      shop_address: "-",
      shop_country: "-",
      shop_province: "-",
      shop_district: "-",
      shop_subdistrict: "-",
      shop_postcode: "-",
      shop_telno: "-",
      status: "-",
      token: "",
      logintime: '',
      role: 'guest',
      messages_chat_unread: 0
    }
  };

  const getToken = () => {
    const tokenString = sessionStorage.getItem('meya-token4');
    if (tokenString !== "undefined") {
      const userToken = JSON.parse(tokenString);
      return userToken?.token
    }
    return null
  };

  const saveToken = userToken => {
    setToken(userToken.token);
    sessionStorage.setItem('meya-token4', JSON.stringify(userToken));
  };

  const [token, setToken] = useState(getToken());
  const [permitdata, setPermitdata] = useState(getTokenObject());

  useEffect(() => {
  }, [location]);

  useEffect(() => {
    var tokenx = getToken()

    if (location.pathname === "/" || location.pathname === "") {
      callback_signout('x')
      setIslogin(true)
    } else {
      setpopup_register(0)
      setpopup_signin(0)

      if (location.pathname === "/home" || location.pathname === "/about") {
        setIslogin(false)
      } else if (location.pathname.includes("/passwordreset")) {
        setIslogin(true)
      } else {
        if (!tokenx) {
          callback_signout('x')
          navigate('/');
          setIslogin(true)
        } else {
          setIslogin(false)
        }
        if (location.pathname === "/myshop/tools/chat") {
          setMessages_chat_unread(0)
        }
      }
    }
  }, [location, token]);

  function callback_mycart(mode, data) {
    if (mode === "add") {
      setMycartlen(mycartlen + 1)
    } else {
      setMycartlen(data)
    }
  }

  function callback_signin(userdata) {
    saveToken(userdata)

    setPermitdata(userdata)
    setMessages_chat_unread(userdata.messages_chat_unread)
    navigate('/home');
  }

  function callback_signout(message) {
    var userinitial = {
      shop_id: "-",
      shop_email: "-",
      shop_username: "-",
      shop_firstname: "-",
      shop_lastname: "-",
      shop_name: "-",
      shop_address: "-",
      shop_country: "-",
      shop_province: "-",
      shop_district: "-",
      shop_subdistrict: "-",
      shop_postcode: "-",
      shop_telno: "-",
      status: "-",
      token: "",
      logintime: '',
      role: 'guest',
      messages_chat_unread: 0
    }
    saveToken(userinitial)
    setPermitdata(userinitial)
  }

  function callback_maindatachange(mode, data) {
    if (mode === "shopitem-add") {
      const fnd = systemdata_shopitemlist.find((element) => {
        return element.shopitem_id === Number(data.shopitem_id);
      })
      if (fnd === undefined) {
        setSystemdata_shopitemlist(oldArray => [...oldArray, data]);
      }
    } else if (mode === "shopitem-edit") {
      setSystemdata_shopitemlist(systemdata_shopitemlist.map(item => {
        if (item.shopitem_id !== Number(data.shopitem_id)) {
          return item;
        } else {
          return data;
        }
      }))
    } else if (mode === "shopitem-delete") {
      setSystemdata_shopitemlist(systemdata_shopitemlist.filter(function (item) {
        return item.shopitem_id !== Number(data)
      }))
    } else if (mode === "shopitem-update-stock") {
      setSystemdata_shopitemlist(systemdata_shopitemlist.map(item => {
        if (item.shopitem_id !== Number(data.shopitem_id)) {
          return item;
        } else {
          return {
            ...item,
            isblank: data.isblank,
            ispro: data.ispro,
            on_stock: data.on_stock,
            on_sold: data.on_sold
          };
        }
      }))
    } else if (mode === "shopitem-update-blank") {
      data.map(item_new => {
        const fnd = systemdata_shopitemlist.find((element) => {
          return element.shopitem_id === Number(item_new.shopitem_id);
        })
        if (fnd !== undefined) {
          setSystemdata_shopitemlist(systemdata_shopitemlist.map(item => {
            if (item.shopitem_id !== Number(fnd.shopitem_id)) {
              return item;
            } else {
              return {
                ...item,
                isblank: data.isblank,
              };
            }
          }))
        }
        return item_new
      })
    } else if (mode === 'shoporder-updatebooking-seller') {
      const nextshoporderlist = systemdata_shoporderlist.map(order => {
        if (order.order_id === data.order_id) {
          return {
            ...order,
            order_status: data.order_status,
            shipping_status: data.shipping_status,
            shipping_purchase_id: data.shipping_purchase_id,
            shipping_tracking_code: data.shipping_tracking_code,
            shipping_courier_tracking_code: data.shipping_courier_tracking_code,
          };
        } else {
          return order;
        }
      });
      setystemdata_shoporderlist(nextshoporderlist)
    } else if (mode === 'shoporder-updatebooking-buyer') {
      const nextshoporderlist = systemdata_shoporderlist.map(order => {
        if (order.order_id === data.order_id) {
          return {
            ...order,
            order_status: data.order_status,
            return_status: data.return_status,
            return_purchase_id: data.return_purchase_id,
            return_tracking_code: data.return_tracking_code,
            return_courier_tracking_code: data.return_courier_tracking_code,
            return_recievename: data.return_recievename,
            return_telno: data.return_telno,
            return_address: data.return_address,
          };
        } else {
          return order;
        }
      });
      setystemdata_shoporderlist(nextshoporderlist)
    } else if (mode === 'shoporder-updatefullstatus') {
      if (data.order_status === "IV_EXP") {
        setystemdata_shoporderlist(systemdata_shoporderlist.filter(function (order) {
          return order.order_id !== data.order_id
        }))
      } else {
        const nextshoporderlist = systemdata_shoporderlist.map(order => {
          if (order.order_id === data.order_id) {
            return {
              ...order,
              order_status: data.order_status,
              shipping_status: data.shipping_status,
              api_paymentstatus: data.api_paymentstatus,
              displayitem: true,
            };
          } else {
            return {
              ...order,
              displayitem: false,
            };
          }
        });
        setystemdata_shoporderlist(nextshoporderlist)
      }
    } else if (mode === 'shoporder-updatefullstatus-fullrecord') {
      const nextshoporderlist = systemdata_shoporderlist.map(order => {
        if (order.order_id === data.order_id) {
          return data;
        } else {
          return {
            ...order,
            displayitem: false,
          };
        }
      });
      setystemdata_shoporderlist(nextshoporderlist)
    } else if (mode === 'shoporder-updatefullstatus-summary') {
      const nextshoporderlist = systemdata_shoporderlist.map(order => {
        const fnd = data.find((element) => {
          return element.order_id === order.order_id;
        })
        if (fnd !== undefined) {
          return {
            ...order,
            shop_subtotal: fnd.shop_subtotal,
            shop_shipping: fnd.shop_shipping,
            shop_shipping_markup: fnd.shop_shipping_markup,
            shop_servicecharge: fnd.shop_servicecharge,
            shop_total: fnd.shop_total,
            shop_income: fnd.shop_income,
            payment_method: fnd.payment_method,
            displayitem: true,
          };
        } else {
          return {
            ...order,
            displayitem: false,
          };
        }
      });
      setystemdata_shoporderlist(nextshoporderlist)
    } else if (mode === 'shoporder-updatepayment') {
      if (data.order_status === "IV_Cancel" || data.order_status === "IV_EXP") {
        setystemdata_shoporderlist(systemdata_shoporderlist.filter(function (order) {
          return order.payment_id !== data.payment_id
        }))
      } else {
        const nextshoporderlist = systemdata_shoporderlist.map(order => {
          if (order.payment_id === data.payment_id) {
            return {
              ...order,
              order_status: data.order_status,
              api_paymentstatus: data.api_paymentstatus,
              api_transactionid: data.api_transactionid,
            };
          } else {
            return order;
          }
        });
        setystemdata_shoporderlist(nextshoporderlist)
      }
    } else if (mode === 'shoporder-updatepayment-frombank') {
      const nextshoporderlist = systemdata_shoporderlist.map(order => {
        if (order.payment_id === data.payment_id) {
          return {
            ...order,
            api_paymentstatus: data.api_paymentstatus,
            api_transactionid: data.api_transactionid,
          };
        } else {
          return order;
        }
      });
      setystemdata_shoporderlist(nextshoporderlist)
    } else if (mode === 'shoporder-updatepayment-method') {
      const nextshoporderlist = systemdata_shoporderlist.map(order => {
        if (order.payment_id === data.payment_id) {
          return {
            ...order,
            payment_method: data.payment_method,
            summary_creditcharge: data.summary_creditcharge,
            summary_net: data.summary_net,
          };
        } else {
          return order;
        }
      });
      setystemdata_shoporderlist(nextshoporderlist)
    } else if (mode === 'myorder-array-add') {
      if (data.length > 0) {
        const arr = data.concat(systemdata_shoporderlist)
        setystemdata_shoporderlist(arr)
      }
    } else if (mode === 'myorder-single-add') {
      const fnd_order = data.find((element) => {
        return element.seller_shopid === permitdata.shop_id;
      })
      if (fnd_order !== undefined) {
        const fnd = systemdata_shoporderlist.find((element) => {
          return element.order_id === fnd_order.order_id;
        })
        if (fnd === undefined) {
          api_getorder_byID(fnd_order.order_id)
        }
      }
    } else if (mode === 'shoporder-updaterefund') {
      setystemdata_shoporderlist(systemdata_shoporderlist.filter(function (order) {
        return order.order_id !== data.order_id
      }))
    } else if (mode === 'shoporder-updateincome') {
      setystemdata_shoporderlist(systemdata_shoporderlist.filter(function (order) {
        return order.order_id !== data.order_id
      }))
    } else if (mode === 'shoporder-updatetransport-normal') {
      const nextshoporderlist = systemdata_shoporderlist.map(order => {
        if (order.order_id === data.order_id) {
          return {
            ...order,
            shipping_status: data.shipping_status,
          };
        } else {
          return order;
        }
      });
      setystemdata_shoporderlist(nextshoporderlist)
    } else if (mode === 'shoporder-updatetransport-return') {
      const nextshoporderlist = systemdata_shoporderlist.map(order => {
        if (order.order_id === data.order_id) {
          return {
            ...order,
            return_status: data.return_status,
          };
        } else {
          return order;
        }
      });
      setystemdata_shoporderlist(nextshoporderlist)
    }
  }

  useEffect(() => {
    if (permitdata !== null) {
      if (permitdata.shop_id !== undefined) {
        if (permitdata.shop_id !== "-" && permitdata.shop_id !== "") {
          api_load_config()
          api_load_myshop()
        }
      }
    }

  }, [permitdata]);

  async function api_load_config() {
    const formData = new FormData();
    formData.append('shop_id', permitdata.shop_id)
    axios.post(app_hostname + "/api/product/common/loadconfig", formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data;',
        "Authorization": "Bearer " + permitdata.token
      },
    })
      .then((response) => {
        if (response.data.status === 'OK') {
          setSystemdata_productcategorylist(response.data.products_category)
          setSystemdata_productitemlist(response.data.products_item)
          setSystemdata_shopitemlist(response.data.products_shopitem)
          setMycartlen(response.data.cart_count)
          setMyorderlen(response.data.order_count)
        } else {

        }
      })
      .catch((err) => {

      });
  }

  async function api_load_myshop() {
    const formData = new FormData();
    formData.append('shop_id', permitdata.shop_id)
    formData.append('shopitem_count', permitdata.shop_id)
    axios.post(app_hostname + "/api/order/myshop/common/getorder", formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data;',
        "Authorization": "Bearer " + permitdata.token
      },
    })
      .then((response) => {
        if (response.data.status === 'OK') {
          setystemdata_shoporderlist(response.data.order)
        }
      })
      .catch((err) => {
      });
  }

  async function api_getorder_byID(order_id) {
    const formData = new FormData();
    formData.append('order_id', order_id)

    axios.post(app_hostname + "/api/order/admin/common/getorderbyid", formData, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data;',
        "Authorization": "Bearer " + permitdata.token
      },
    })
      .then((response) => {
        if (response.data.status === 'OK') {
          var arr = response.data.order.concat(systemdata_shoporderlist)
          setystemdata_shoporderlist(arr)
        }
        else {

        }
      })
      .catch((err) => {

      });
  }


  useEffect(() => {
    const interval = setInterval(() => {
      setNotify_count(notify_count + 1);
      if (notify_count > 1 && permitdata !== null) {
        setNotify_count(1);

        if (permitdata.token !== "" && permitdata.token !== "-" && isConnectionOpen_notify === false) {
          notify_ws.current = new WebSocket("wss://marketlive.meya.co.th/live/chat/connect?username=" + permitdata.shop_id + "&&token=" + permitdata.token);

          notify_ws.current.onopen = () => {
            console.log("Connection opened");
            setIsConnectionOpen_notify(true);
          };

          notify_ws.current.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setMessages_raw(data)
          };
          notify_ws.current.onclose = (event) => {
            console.log("Connection Lose");
            setIsConnectionOpen_notify(false);
          };

          return () => {
            console.log("Cleaning up...");
            notify_ws.current.close();
          };
        }
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [notify_count]);

  useEffect(() => {
    if (permitdata !== null) {
      if (permitdata.shop_id !== undefined) {
        if (permitdata.shop_id !== "" && permitdata.shop_id !== "-") {
          notify_ws.current = new WebSocket("wss://marketlive.meya.co.th/live/chat/connect?username=" + permitdata.shop_id + "&&token=" + permitdata.token);

          notify_ws.current.onopen = () => {
            console.log("Connection opened");
            setIsConnectionOpen_notify(true);
          };

          notify_ws.current.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setMessages_raw(data)
          };
          notify_ws.current.onclose = (event) => {
            console.log("Connection Lose");
            setIsConnectionOpen_notify(false);
          };

          return () => {
            console.log("Cleaning up...");
            notify_ws.current.close();
          };
        }
      }
    }
  }, [permitdata]);

  useEffect(() => {
    if (permitdata !== null) {
      if (messages_raw.from !== undefined && messages_raw.to !== undefined) {
        if (messages_raw.topic === "chat" && ((messages_raw.from === "admin" && messages_raw.to === permitdata.shop_id) || (messages_raw.to === "admin" && messages_raw.from === permitdata.shop_id))) {
          if (location.pathname === "/myshop/tools/chat") {
            setMessages_chat_buffer(messages_raw);
          } else {
            setMessages_chat_buffer({});
            setMessages_chat_unread(messages_chat_unread + 1)
          }
        }
        if ((messages_raw.topic === "order" && messages_raw.to === permitdata.shop_id)) {
          const data_in = JSON.parse(messages_raw.data);

          if (messages_raw.popup_enable === true) {
            var id_ref = ""
            if (data_in.order_id !== undefined) {
              id_ref = data_in.order_id
            }
            if (id_ref === "" && data_in.payment_id !== undefined) {
              id_ref = data_in.order_id
            }
            if (id_ref === "") {
              id_ref = generate_token(30)
            }

            const fnd_id = notify_data.find((element) => {
              return element.id === id_ref;
            })
            if (fnd_id === undefined) {
              var data = {
                id: generate_token(30),
                icon: messages_raw.popup_class,
                title: messages_raw.popup_title,
                subtitle: messages_raw.popup_subtitle,
                actions: ["รับทราบ"]
              }
              setNotify_data(oldArray => [...oldArray, data]);
            } else {
              const nextnotify = notify_data.map(notifyx => {
                if (notifyx.id === id_ref) {
                  return {
                    ...notifyx,
                    icon: messages_raw.popup_class,
                    title: messages_raw.popup_title,
                    subtitle: messages_raw.popup_subtitle,
                  };
                } else {
                  return notifyx;
                }
              });
              setNotify_data(nextnotify)
            }
          }
          callback_maindatachange(messages_raw.mode, data_in)
        }
      }
    }
  }, [messages_raw]);

  function open_signin() {
    setpopup_signin(popup_signin + 1);
  }

  function open_register() {
    setpopup_register(popup_register + 1);
  }

  function callback_notify_ack(notify_id) {
    document.getElementById(notify_id).style.animation = "flyOutRight 0.3s ease-out forwards"
  }

  function callback_notify_animation(notify_id) {
    var cls = document.getElementById(notify_id)
    if (cls.className === 'my-3 notification__box flyInRight') {
      cls.className = 'my-3 notification__box'
    } else if (cls.className === 'my-3 notification__box') {
      setNotify_data(notify_data.filter(function (item) {
        return item.id !== notify_id
      }))
    }
  }

  return (
    islogin === false ?
      <div className="page-container">
        <div className="page-wrap">
          <Navbar
            islogin={false}
            permitdata={permitdata}
            systemdata_productitemlist={systemdata_productitemlist}
            systemdata_productcategorylist={systemdata_productcategorylist}
            mycartlen={mycartlen}
            myorderlen={myorderlen}
            messages_chat_unread={messages_chat_unread}
          ></Navbar>

          <Routes>
            <Route
              path="/home"
              element={
                <HomePage
                  permitdata={permitdata}
                />} />
            <Route
              path="/marketplace"
              element={
                <MarketPlacePage
                  app_hostname={app_hostname}
                  permitdata={permitdata}
                  systemdata_productcategorylist={systemdata_productcategorylist}
                />} />
            <Route
              path="/marketplace/shopseller"
              element={
                <ShopSellerPage
                  app_hostname={app_hostname}
                  permitdata={permitdata}
                  systemdata_productcategorylist={systemdata_productcategorylist}
                />} />
            <Route
              path="/marketplace/productview"
              element={
                <ProductViewPage
                  app_hostname={app_hostname}
                  permitdata={permitdata}
                  callback_mycart={(mode, data) => {
                    callback_mycart(mode, data)
                  }}
                />} />
            <Route
              path="/marketplace/mycart"
              element={
                <MyCartPage
                  app_hostname={app_hostname}
                  permitdata={permitdata}
                  mycartlen={mycartlen}
                  callback_mycart={(mode, data) => {
                    callback_mycart(mode, data)
                  }}
                />} />
            <Route
              path="/marketplace/mypayment"
              element={
                <MyPaymentPage
                  app_hostname={app_hostname}
                  permitdata={permitdata}
                  callback_mycart={(mode, data) => {
                    callback_mycart(mode, data)
                  }}
                  callback_maindatachange={(mode, data) => {
                    callback_maindatachange(mode, data)
                  }}
                />} />
            <Route
              path="/myshop/*"
              element={
                <MyShopPage
                  app_hostname={app_hostname}
                  permitdata={permitdata}
                  systemdata_productitemlist={systemdata_productitemlist}
                  systemdata_productcategorylist={systemdata_productcategorylist}
                  systemdata_shoporderlist={systemdata_shoporderlist}
                  systemdata_shopitemlist={systemdata_shopitemlist}
                  messages_chat_buffer={messages_chat_buffer}
                  isConnectionOpen_notify={isConnectionOpen_notify}
                  notify_ws={notify_ws}
                  callback_mycart={(mode, data) => {
                    callback_mycart(mode, data)
                  }}
                  callback_maindatachange={(mode, data) => {
                    callback_maindatachange(mode, data)
                  }}
                />} />
            <Route
              path="/about"
              element={
                <AboutPage
                  permitdata={permitdata}
                />} />
          </Routes>
          <div className={"notification " + (notify_data.length > 0 ? "" : "d-none")}>
            {
              notify_data.map((notify, index) => (
                index < 10 ?
                  <div className="my-3 notification__box flyInRight" id={notify.id} key={notify.id} onAnimationEnd={() => callback_notify_animation(notify.id)}>
                    <div className="notification__content">
                      <div className={"notification__icon " + notify.icon}>
                        {
                          notify.icon === "error" ?
                            <i className='fe-alert-triangle'></i>
                            : notify.icon === "warning" ?
                              <i className='fe-bell'></i>
                              : notify.icon === "success" ?
                                <i className='fe-check-circle'></i>
                                :
                                <i className='fe-help-circle'></i>
                        }
                      </div>
                      <div className="notification__text">
                        <div className="notification__text-title">{notify.title}</div>
                        <div className="notification__text-subtitle">{notify.subtitle}</div>
                      </div>
                    </div>
                    <div className="notification__btns">
                      <button className="notification__btn" type="button" data-dismiss="ZjYyO3zBVV7SUDG8cLBt5NyUllmyuT" onClick={() => { callback_notify_ack(notify.id) }}><span className="notification__btn-text">รับทราบ</span></button>
                    </div>
                  </div>
                  :
                  null
              ))
            }
          </div>

        </div>
        <Footer
          islogin={false}
          permitdata={permitdata}
        ></Footer>
      </div>
      :
      <div className="page-container">
        <div className="page-wrap">
          <Navbar
            islogin={true}
            isreset={true}
            permitdata={permitdata}
            callback_popup_register={open_register}
            callback_popup_signin={open_signin}
          ></Navbar>
          <Routes>
            <Route
              path="/home"
              element={
                <HomePage
                  permitdata={permitdata}
                />} />
            <Route
              path="/"
              element={
                <LoginPage
                  permitdata={permitdata}
                  popup_register={popup_register}
                  popup_signin={popup_signin}
                  callback_signin={(userdata) => {
                    callback_signin(userdata)
                  }}
                  app_hostname={app_hostname} />
              } />
            <Route
              exact path=""
              element={
                <LoginPage
                  permitdata={permitdata}
                  popup_register={popup_register}
                  popup_signin={popup_signin}
                  callback_signin={(userdata) => {
                    callback_signin(userdata)
                  }}
                  app_hostname={app_hostname} />
              } />
            <Route
              path="/passwordreset/*"
              element={
                <PasswordResetPage
                  permitdata={permitdata}
                  popup_register={popup_register}
                  popup_signin={popup_signin}
                  callback_signin={(userdata) => {
                    callback_signin(userdata)
                  }}
                  app_hostname={app_hostname} />
              } />
          </Routes>
        </div>
        <Footer
          islogin={true}
          permitdata={permitdata}
        ></Footer>
      </div>
  );
}

export default App;
